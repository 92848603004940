body {
    margin: 0;
    font-family: 'Poppins','Playfair Display','Merriweather', serif;
}

.App {
    text-align: center;
    display: flex;
    justify-content: space-evenly;
}

.header-container {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
}

.banner-container h1 {
    margin: 0;
}

.header-name {
    text-align: start;
    font-size: 1.4em;
}

.header-date {
    text-align: end;
    font-size: 1em;
}

.content-container {
    display: flex;
    flex-flow: column;
}

.container1 {
    display: flex;
    flex-flow: column;
}

.container-padding {
    padding: 0.4em;
}


.flexGrow1 {
    flex-grow: 1;
}

.flexGrow2 {
    flex-grow: 2;
}

.flexGrow3 {
    flex-grow: 3;
}

@media only screen and (min-width: 1025px) {
    .App{
        align-items: flex-start;
        padding: 2em;
    }

    .content-container {
        padding: 0 5em;
        flex-grow: 3;
    }

    .container1 {
        flex-flow: row;
    }
}
@media only screen and (max-width: 700px) {
    .header-container {
        flex-flow: column;
    }
    .header-date {
        text-align: start;
    }

    .App {
        flex-flow: column;
    }
}