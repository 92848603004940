.skillContainer {
  display: flex;
  flex-direction: row;
  margin: 1em;
}

.fillContainer {
  width: 90%;
}

.fill {
  background-color: red;
  height: 100%;
  border-radius: 5px;
  text-align: left;
}

.name {
  padding: 1px;
}

.value {
  width: 10%;
  text-align: right;
}
