.experience-container {
  display: flex;
  flex-flow: column;
}

.experience-content {
  display: flex;
  flex-flow: column;
}

.experience-designation {
  text-align: left;
  margin: 0;
  font-weight: bold;
  font-size: 1.1em;
}

.experience-highlight {
  text-align: start;
}
