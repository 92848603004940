.address-container {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: space-around;
}

@media only screen and (min-width: 701px) {
  .address-container {
    flex-flow: row nowrap;
  }
}
