.main-container {
  text-align: left;
}

.footer-container {
  display: flex;
  flex-flow: row nowrap;
}

.keyword {
  margin: 0 10px 0 0;
  background-color: red;
  padding: 5px;
  border-radius: 5px;
}
