.card-container {
    display: flex;
    flex-flow: column;
    border-radius: 0.2em;
    border: #DCE0E0 solid 0.1em;
    margin: 1em;
    padding: 1em;
}

.seperator {
    width: 100%;
    height: 0.01em;
    border-bottom: #DCE0E0 solid 0.01em;
    padding: 0.2em 0;
}
